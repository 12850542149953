<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <surveys
          :in-program-detail="true"
          :program="defaultProgram"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Surveys from "@/views/components/forms/surveys/Surveys.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Surveys,
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
  },
};
</script>
